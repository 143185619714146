export const myProjects = [
  {
    id: 1,
    title: 'Hang In There Chez and Colgan',
    description:
      'Hang In There Chez and Colgan is designed to show the user a series of randomly generated motivational posters and allows them to create their own posters.',
    github_repository:
      'https://github.com/cagallo/hang-in-there-chez-and-colgan',
    external_link:
      'https://colganmeanor.github.io/hang-in-there-chez-and-colgan/',
    project_type: 'pair',
    tech_stack: ['Vanilla JS', 'HTML', 'CSS'],
    img: 'https://user-images.githubusercontent.com/87510749/132140786-de178e39-7ff0-41b1-a24a-f20ebad07fba.gif',
  },
  {
    id: 2,
    title: 'Self Care Center',
    description:
      'Self Care Center is an application allows users the ability to select an affirmation or mantra and have a corresponding message appear on the page by clicking the Receive Message button.',
    github_repository: 'https://github.com/cagallo/self-care-center',
    external_link: 'https://cagallo.github.io/self-care-center/',
    project_type: 'solo',
    tech_stack: ['Vanilla JS', 'HTML', 'CSS'],
    img: 'https://user-images.githubusercontent.com/78453792/133010382-aa5ab9e4-d30c-4419-9197-5421ecba6693.png',
  },
  {
    id: 3,
    title: 'IdeaBox',
    description:
      'IdeaBox is an application lets users create, display, record and archive idea cards.',
    github_repository: 'https://github.com/cagallo/ideabox',
    external_link: null,
    project_type: 'group',
    tech_stack: ['Vanilla JS', 'HTML', 'CSS'],
    img: 'https://user-images.githubusercontent.com/78453792/134266981-abd472db-b393-4f4b-b025-abed94ccb5c5.gif',
  },
  {
    id: 4,
    title: 'Tic Tac Toe',
    description:
      'Tic Tac Toe is a dog and cat themed tic-tac-toe game that keeps track of wins for both users.',
    github_repository: 'https://github.com/cagallo/tic-tac-toe',
    external_link: 'https://cagallo.github.io/tic-tac-toe/',
    project_type: 'solo',
    tech_stack: ['Vanilla JS', 'HTML', 'CSS'],
    img: 'https://user-images.githubusercontent.com/78453792/135154432-e0207226-cb92-495f-a1a4-6156d1318e25.png',
  },
  {
    id: 5,
    title: 'Flash Cards',
    description:
      'FlashCards is a simple and fun multiple choice game that is played in the Terminal.',
    github_repository: 'https://github.com/cagallo/flashcards-starter',
    external_link: null,
    project_type: 'solo',
    tech_stack: ['Vanilla JS', 'HTML', 'CSS', 'Mocha', 'Chai', 'TDD'],
    img: 'https://user-images.githubusercontent.com/78453792/144504726-fd2d3dff-764e-48f0-917e-6aae36706ca0.png',
  },
  {
    id: 6,
    title: 'Whats Cookin',
    description:
      'Hungry and not sure what to cook tonight? You are in luck! Whats Cookin is an easy-to-use web app for organizing all your favorite recipes in one place.',
    github_repository: 'https://github.com/cagallo/whats-cookin',
    external_link: null,
    project_type: 'group',
    tech_stack: [
      'Vanilla JS',
      'HTML',
      'CSS',
      'Mocha',
      'Chai',
      'TDD',
      'Rest API',
      'webpack',
    ],
    img: 'https://user-images.githubusercontent.com/78453792/145901951-52832b77-6330-411b-98f4-ff2dbcee5cbb.png',
  },
  {
    id: 7,
    title: 'Overlook Hotel',
    description:
      'The application is a hotel management tool where customers can log in, book a room, and view both their past and future reservations. This was the final solo project for Mod 2 of the Front End Engineering program at the Turing School of Software & Design.',
    github_repository: 'https://github.com/cagallo/overlook-hotel',
    external_link: null,
    project_type: 'solo',
    tech_stack: [
      'Vanilla JS',
      'HTML',
      'SASS',
      'Mocha',
      'Chai',
      'TDD',
      'Rest API',
      'webpack',
      'moment.js',
    ],
    img: 'https://user-images.githubusercontent.com/78453792/150062585-97dec410-3d5d-4f09-936e-c2973a34dd3f.gif',
  },
  {
    id: 8,
    title: 'Rancid Tomatillos',
    description:
      'Rancid Tomatillos is an easy-to-use web application for viewing movie posters, details and trailers.',
    github_repository: 'https://github.com/cagallo/rancid-tomatillos',
    external_link: null,
    project_type: 'pair',
    tech_stack: [
      'React',
      'React Router',
      'HTML',
      'CSS',
      'Cypress',
      'Rest API',
      'webpack',
      'day.js',
      'PropTypes',
      'Heroku',
    ],
    img: 'https://user-images.githubusercontent.com/78453792/153780939-7df21bbb-41cf-4440-9f77-09f8e0c88773.gif',
  },
  {
    id: 9,
    title: 'Book Club UI',
    description:
      'Welcome to Book Club--your new go-to app where you can find out all the details about the latest bestsellers! If you are a book lover, you have come to the right place.',
    github_repository: 'https://github.com/cagallo/book-club-ui',
    external_link: null,
    project_type: 'group',
    tech_stack: [
      'React',
      'React Router',
      'HTML',
      'CSS',
      'Cypress',
      'Rest API',
      'webpack',
      'day.js',
      'PropTypes',
      'Heroku',
    ],
    img: 'https://camo.githubusercontent.com/59c2c216606dc96f285037654c1f9ff9596ebf006f10cca56c2ff5a6d31895b3/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f375139555830365a364662423430776445502f67697068792e676966',
  },
  {
    id: 10,
    title: 'Book Club API',
    description:
      'Book Club API is a collection of books taken from the NY Times best sellers list. It was created to accompany Book Club UI',
    github_repository: 'https://github.com/cagallo/book-club-ui',
    external_link: null,
    project_type: 'group',
    tech_stack: [
      'Express',
      'Node',
      'Postgres',
      'Knex',
      'Postman',
      'PGAdmin14',
      'Heroku',
    ],
    img: null,
  },
  {
    id: 11,
    title: 'Trivia Night',
    description:
      'Trivia Night is an trivia questions game that allows the user to test their trivia knowledge from a variety of topics or choose a mix of questions from all categories.',
    github_repository: 'https://github.com/cagallo/trivia-night-ui',
    external_link: null,
    project_type: 'solo',
    tech_stack: [
      'React',
      'React Router',
      'HTML',
      'CSS',
      'Cypress',
      'Rest API',
      'webpack',
      'PropTypes',
      'Heroku',
    ],
    img: 'https://user-images.githubusercontent.com/78453792/156971553-5a5ff467-8388-4ede-aee1-4c9d9184e02b.gif',
  },
  {
    id: 12,
    title: 'Trivia Questions API',
    description:
      'Trivia Questions API is a collection of trivia questions from various categories and it was created to accompany Trivia Night UI',
    github_repository: 'https://github.com/cagallo/trivia-questions-api',
    external_link: null,
    project_type: 'solo',
    tech_stack: [
      'Express',
      'Node',
      'Postgres',
      'Knex',
      'Postman',
      'PGAdmin14',
      'Heroku',
    ],
    img: null,
  },
  {
    id: 13,
    title: 'No Use Cryin Over Shared Milk',
    description:
      'No Use Cryin Over Shared Milk is an application that enables families that are dealing with an under-production of breast milk to connect with families that are over-producing breast milk in a free and easily accessible manner.',
    github_repository: 'https://github.com/Shared-Milk/shared-milk-ui',
    external_link: 'https://no-use-cryin-over-shared-milk.herokuapp.com/',
    project_type: 'group',
    tech_stack: [
      'React',
      'React Router',
      'GraphQL',
      'Apollo Client',
      'HTML',
      'SASS',
      'CircleCI',
      'Cypress',
      'Rest API',
      'webpack',
      'PropTypes',
      'Heroku',
    ],
    img: 'https://github.com/Shared-Milk/shared-milk-ui/raw/main/.github/Site-Overview.gif',
  },
];
